.table-of-contents .heading2 {
  margin-left: 0px;
}

.table-of-contents .heading3 {
  margin-left: 10px;
}

.selected-heading {
  color: #59337d;
  position: relative;
}

.selected-heading-wrapper::before {
  content: ' ';
  position: absolute;
  display: inline-block;
  left: -30px;
  top: 4px;
  z-index: 10;
  height: 4px;
  width: 4px;
  background-color: transparent;
  border: solid 4px transparent;
  border-radius: 50%;
}

.normal-heading {
  cursor: pointer;
  line-height: 20px;
  font-size: 16px;
  margin: 10px 0px;
  transition: color 0.2s ease;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  hyphens: auto;
}
.normal-heading:hover {
  color: #59337d;
  cursor: pointer;
}


.table-of-contents {
  color: #717171;
  position: fixed;
  top: 200px;
  right: 0px;
  padding: 10px;
  width: 330px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 1;
  max-height: 50vh;
}

.first-heading {
  color: black;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: -20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  hyphens: auto;
}

.headings {
  list-style: none;
  margin-top: 0;
  margin-left: 6px;
  padding: 0;
  overflow: scroll;
  width: 300px;
  max-height: 50vw;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: bold;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.headings::-webkit-scrollbar {
  display: none;
}

.headings::before {
  content: ' ';
  position: absolute;
  height: 96%;
  width: 2px;
  right: 300px;
  margin-top: 5px;
  background-color: #ccd0d5;
  border-radius: 2px;
}

.normal-heading-wrapper {
  margin-left: 32px;
  position: relative;
}
